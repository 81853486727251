import { graphql } from "gatsby"
import React, { FC, useState, useRef, useLayoutEffect } from "react"
import { Layout } from "src/components/Layout"
import { Props } from "./types"
import * as styles from "./styles.module.scss"
import Link from "src/utils/Link"
import { usePages } from "src/context/pages"
import ArrowLink from "src/components/ArrowLink"
import { useSupportRequest } from "src/hooks/useSupportRequest"
import { useHasBeenFocusedField } from "src/hooks/useHasBeenFocusedField"
import LogosList from "src/components/LogosList"
import Logo from "src/images/structured-data-logo.png"
import { useNewsletterSubscription } from "src/hooks/useNewsletterSubscription"

const Template: FC<Props> = ({
  data: {
    file: { childYaml: texts },
  },
}) => {
  const { getPage, currentPath } = usePages()
  const { field, hasBeenFocused, focusAll } = useHasBeenFocusedField()

  const [email, setEmail] = useState("")
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const companyRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const messageRef = useRef<HTMLTextAreaElement>(null)

  const supportRequest = useSupportRequest()
  const newsletter = useNewsletterSubscription("newsletter", texts)
  const [phase, setPhase] = useState<"fill-form" | "success">("fill-form")

  async function submit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault()

    const firstName = firstNameRef.current?.value!
    const lastName = lastNameRef.current?.value!
    const email = emailRef.current?.value!
    const company = companyRef.current?.value!
    const message = messageRef.current?.value!

    setEmail(email)

    const response = await supportRequest({
      type: "sales",
      email: email,
      name: `${firstName} ${lastName}`,
      subject: `New submission from the Submit page.`,
      text: `First name: ${firstName}
      Last name: ${lastName}
      Work email: ${email}
      Company: ${company}
      Notes: ${message}`,
    })

    await newsletter.subscribe("newsletter", email, false)

    if (response.status === 202) {
      setPhase("success")
    } else {
      console.error(response)
    }
  }

  useLayoutEffect(() => {
    if (phase === "success") {
      window.scrollTo(0, 0)
    }
  }, [phase])

  return (
    <Layout
      title={texts.metaTitle}
      description={texts.metaDescription}
      openGraph={{
        title: texts.metaTitle,
        description: texts.metaDescription,
        url: `${process.env.GATSBY_CANONICAL_URL}${currentPath}`,
        images: [
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 800,
            height: 600,
            alt: "Flow logo",
          },
          {
            url: `${process.env.GATSBY_CANONICAL_URL}${Logo}`,
            width: 900,
            height: 800,
            alt: "Flow logo",
          },
        ],
      }}
      className={phase === "success" ? styles.layout : ""}
    >
      <div className={`content-wrapper ${styles.sales}`}>
        {phase === "success" && (
          <>
            <h1 className={`${styles.title} ${styles.titleSubmitted}`}>
              {texts.headerSubmitted}
            </h1>
            <p className={`${styles.subtitle} ${styles.subtitleSubmitted}`}>
              {texts.textSubmitted} {email}
            </p>
            <Link
              to={getPage("blog").url}
              className={`button button-second ${styles.buttonSubmitted}`}
            >
              {texts.buttonSubmitted}
            </Link>
            <ArrowLink
              to={getPage("index").url}
              text={texts.linkSubmitted}
              className={styles.linkSubmitted}
            />
          </>
        )}
        {phase === "fill-form" && (
          <>
            {" "}
            <h1 className={styles.title}>{texts.header}</h1>
            <p className={styles.subtitle}>{texts.subtitle}</p>
            <form
              className={`${styles.form}`}
              onSubmit={submit}
              onInvalid={e => {
                e.preventDefault()
                focusAll()
              }}
            >
              <div className={styles.contentInput}>
                <label className={styles.labelInput}>
                  {texts.firstNameLabel}
                </label>
                <input
                  className={`${styles.input} ${
                    hasBeenFocused("firstName") ? styles.hasBeenFocused : ""
                  }`}
                  type="text"
                  placeholder={texts.firstNamePlaceholder}
                  required
                  ref={firstNameRef}
                  {...field("firstName")}
                />
              </div>
              <div className={styles.contentInput}>
                <label className={styles.labelInput}>
                  {texts.lastNameLabel}
                </label>
                <input
                  className={`${styles.input} ${
                    hasBeenFocused("lastName") ? styles.hasBeenFocused : ""
                  }`}
                  type="text"
                  required
                  placeholder={texts.lastNamePlaceholder}
                  ref={lastNameRef}
                  {...field("lastName")}
                />
              </div>
              <div className={styles.contentInput}>
                <label className={styles.labelInput}>{texts.emailLabel}</label>
                <input
                  className={`${styles.input} ${
                    hasBeenFocused("email") ? styles.hasBeenFocused : ""
                  }`}
                  type="email"
                  required
                  placeholder={texts.emailPlaceholder}
                  ref={emailRef}
                  {...field("email")}
                />
              </div>
              <div className={styles.contentInput}>
                <label className={styles.labelInput}>
                  {texts.companyLabel}
                </label>
                <input
                  className={`${styles.input} ${
                    hasBeenFocused("company") ? styles.hasBeenFocused : ""
                  }`}
                  type="text"
                  required
                  placeholder={texts.companyPlaceholder}
                  ref={companyRef}
                  {...field("company")}
                />
              </div>
              <div className={`${styles.contentInput} ${styles.alignTop}`}>
                <label className={styles.labelInput}>
                  {texts.messageLabel}
                </label>
                <textarea
                  className={`${styles.textarea} ${
                    hasBeenFocused("message") ? styles.hasBeenFocused : ""
                  }`}
                  required
                  placeholder={texts.messagePlaceholder}
                  ref={messageRef}
                  {...field("message")}
                />
              </div>
              <button className={`button button-second ${styles.button}`}>
                {texts.signUp}
              </button>
            </form>
            <LogosList text={texts.logosTitle} />
            <p className={styles.more}>{texts.contact}</p>
            <ArrowLink
              className={styles.moreLink}
              text={texts.contactLink}
              target="_blank"
              to="https://developer.getflow.com"
            />
          </>
        )}{" "}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($ymlPath: String!) {
    file(absolutePath: { eq: $ymlPath }) {
      childYaml {
        metaTitle
        metaDescription
        header
        subtitle
        firstNameLabel
        firstNamePlaceholder
        lastNameLabel
        lastNamePlaceholder
        emailLabel
        emailPlaceholder
        companyLabel
        companyPlaceholder
        messageLabel
        messagePlaceholder
        signUp
        logosTitle
        contact
        contactLink
        headerSubmitted
        textSubmitted
        buttonSubmitted
        linkSubmitted
      }
    }
  }
`

export default Template
